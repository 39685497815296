<template>

  <div style="display: flex;height: 100%">

    <div :style="{width:`${isPc ? '40%' : '100%'}`}"
         style="width: 40%;height: 100%;border-right: 1px solid rgba(0,0,0,0.42);overflow: hidden;">
      <div style="height: 50%">
        <div style="width: 100%;height: 40%;display: flex;">
          <div style="width: 50%;padding: 15px">
            <a-tag color="orange"
                   style="width: 100%;height: 100%;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
              <div style="width: 40%;display:flex;justify-content: center;align-items: center">
                <a-icon type="sketch" style="font-size: 60px;color: rgba(201,1,38,0.89)"/>
              </div>

              <div style="width: 60%">
                <div
                    style="width: 100%;height:40%;font-size: 16px;display: flex;align-items: center;font-weight: bold">
                  剩余次数
                </div>

                <div
                    style="width: 100%;height: 50%;font-size: 23px;display: flex;justify-content: center;align-items: center">
                  {{ user_info.score }}
                </div>

              </div>

            </a-tag>
          </div>
          <div style="width: 50%;padding: 15px">
            <a-tag color="purple"
                   style="width: 100%;height: 100%;display: flex;border-radius: 15px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.15);background-color: white">
              <div style="width: 40%;display:flex;justify-content: center;align-items: center">
                <a-icon type="heat-map" style="font-size: 60px;color: purple"/>
              </div>

              <div style="width: 60%">
                <div
                    style="width: 100%;height:40%;font-size: 16px;display: flex;align-items: center;font-weight: bold">
                  已用次数
                </div>

                <div
                    style="width: 100%;height: 50%;font-size: 23px;display: flex;justify-content: center;align-items: center">
                  {{ user_info.makeImageCount }}
                </div>

              </div>

            </a-tag>
          </div>

        </div>

        <div
            style="width: 100%;height: 70%;padding: 10px;display: flex;flex-wrap: wrap;justify-content:space-around;align-items: center">
          <div v-for="(item,i) in actionObj.actions"
               :key="i" style="width: 25%;height: 25%;margin: 0 1px;float: right;">
            <a-tag :color="actionObj.color_list[Math.floor(Math.random()*7)]" class="advert"
                   @click="go(item.link,item.cli)"
                   style="width: 100%;height: 100%;border-radius: 15px;display: flex;justify-content: center;align-items: center;font-size: 20px;cursor:pointer;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.1)">
              <a-icon :type="item.type"/>&nbsp;{{ item.name }}
            </a-tag>
          </div>
          <div style="width: 100%;height: 10%"></div>
        </div>
      </div>
      <div style="width: 100%;height: 50%;overflow: hidden" ref="card_head">
        <div class="advert" @click="toDo"
             :style="{height:`${left_card.card_height}px`,width:`${left_card.card_width}px`,backgroundImage:`url('${website_info.website_info[0].adv_img}')`,backgroundRepeat:'no-repeat'}"
             style="cursor:pointer;background-size: 100% 100%;background-position: center"></div>
      </div>

    </div>
    <div style="width: 60%" v-if="isPc">
      <useRecord :isComp="true"></useRecord>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import useRecord from "@/pages/personality/UseRecord";

export default {
  name: "PersonHome",
  components: {
    useRecord
  },
  data() {
    return {
      left_card: {
        card_height: 0,
        card_width: 0,

      },
      isPc: true,
      actionObj: {
        color_list: ['pink', 'red', 'orange', 'green', 'cyan', 'blue', 'purple'],
        actions: [
          {
            name: '在线作图',
            link: '/getsteamId',
            cli: true,
            type: 'heat-map'
          },
          {
            name: '查黑名单',
            link: '/personality/blackList',
            cli: true,
            type: 'user-delete'
          },
          {
            name: '封禁查询',
            link: '/personality/vacFinder',
            cli: true,
            type: 'close-circle'
          },
          {
            name: '战绩查询',
            link: '/index.html',
            cli: false,
            type: 'radar-chart'
          },
          {
            name: '会员充值',
            link: '/personality/payment',
            cli: true,
            type: 'crown'
          },
          {
            name: '图片压缩',
            link: '/personality/imgCompress',
            cli: true,
            type: 'file-zip'
          },
        ]
      }
    }
  },
  methods: {
    ...mapActions('userInfo', ['get_user_info']),
    go(path, cli) {
      if (cli) {
        this.$router.push(path)
      } else {
        location.href = `https://${location.host}${path}`
      }
    },
    toDo() {
      location.href = this.website_info.website_info[0].adv_url
    },
  },
  computed: {
    ...mapState('userInfo', ['user_info']),
    ...mapState('footer', ['website_info']),
  },
  mounted() {
    const elResize = require('element-resize-detector')
    const erd = elResize()
    const self = this
    this.isPc = !(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))

    erd.listenTo(this.$refs.card_head, function (element) {
      self.left_card.card_height = element.offsetHeight;
      self.left_card.card_width = element.offsetWidth;
    })
  },

  async created() {
    await this.get_user_info()
  }
}
</script>

<style scoped lang="less">
.advert {
  transition: all 0.3s;
}

.advert:hover {
  transform: scale(1.1);
}


</style>

<style scoped>
.ant-carousel >>> .slick-slide {
  text-align: center;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>